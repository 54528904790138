<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <v-row>
                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-select
                                v-model="editedItem.card_type_id.value"
                                :error-messages="editedItem.card_type_id.error"
                                :items="cardTypes"
                                attach
                                :label="$t('admin.cards.type')"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @change="editedItem.card_type_id.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-select
                                v-model="editedItem.card_layout_id.value"
                                :error-messages="editedItem.card_layout_id.error"
                                :items="cardLayouts"
                                attach
                                :label="$t('admin.cards.layout')"
                                item-text="name"
                                item-value="id"
                                :disabled="isOnlyView"
                                @change="createI18nMapComponents"
                                clearable
                            />
                        </v-col>

                        <!-- <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-file-input
                                v-model="editedItem.img.value"
                                :label="$t('admin.cards.image')"
                                accept="image/*"
                                :error-messages="editedItem.img.error"
                                :disabled="isOnlyView"
                                @change="preview_img"
                            />
                            <v-img
                                class="img_preview mx-auto"
                                :src="editedItem.img.url"
                                contain
                                max-width="200px"
                            />
                        </v-col> -->

                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.question.value"
                                class="purple-input mb-2"
                                :label="$t('admin.cards.question')"
                                :error-messages="editedItem.question.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.question.error = ''"
                                hide-details
                            />

                            <v-text-field
                                v-model="editedItem.question_self.value"
                                class="purple-input mb-2"
                                :label="$t('admin.cards.questionSelf')"
                                :error-messages="editedItem.question_self.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.question_self.error = ''"
                                hide-details
                            />

                            <v-text-field
                                v-if="hasFeedForward"
                                v-model="editedItem.feedforward.value"
                                class="purple-input mb-2"
                                :label="$t('admin.cards.feedForward')"
                                :error-messages="editedItem.feedforward.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.feedforward.error = ''"
                                hide-details
                            />
                        </v-col>

                        <v-col
                            v-for="(langData, langCode) in i18nMapComponents"
                            :key="langCode"
                            cols="12"
                        >
                            <h6
                                v-text="langData.nameLang"
                                class="primary mb-0"
                            />

                            <v-text-field
                                v-model="langData.question"
                                class="purple-input mb-2"
                                :label="$t('admin.cards.question')"
                                :disabled="isOnlyView"
                                hide-details
                            />

                            <v-text-field
                                v-model="langData.question_self"
                                class="purple-input mb-2"
                                :label="$t('admin.cards.questionSelf')"
                                :disabled="isOnlyView"
                                hide-details
                            />

                            <v-text-field
                                v-if="hasFeedForward"
                                v-model="langData.feedforward"
                                class="purple-input mb-2"
                                :label="$t('admin.cards.feedForward')"
                                :disabled="isOnlyView"
                                hide-details
                            />
                        </v-col>
                    </v-row>

                    <!--
                    <v-row v-if="hasAnswer">
                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.answer_1.value"
                                class="purple-input"
                                :label="$t('admin.cards.answer_1')"
                                :error-messages="editedItem.answer_1.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.answer_1.error = ''"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.answer_2.value"
                                class="purple-input"
                                :label="$t('admin.cards.answer_2')"
                                :error-messages="editedItem.answer_2.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.answer_2.error = ''"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.answer_3.value"
                                class="purple-input"
                                :label="$t('admin.cards.answer_3')"
                                :error-messages="editedItem.answer_3.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.answer_3.error = ''"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.answer_4.value"
                                class="purple-input"
                                :label="$t('admin.cards.answer_4')"
                                :error-messages="editedItem.answer_4.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.answer_4.error = ''"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.answer_5.value"
                                class="purple-input"
                                :label="$t('admin.cards.answer_5')"
                                :error-messages="editedItem.answer_5.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.answer_5.error = ''"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.answer_ok.value"
                                class="purple-input"
                                :label="$t('admin.cards.answer_ok')"
                                :error-messages="editedItem.answer_ok.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.answer_ok.error = ''"
                            />
                        </v-col>
                    </v-row>
-->

                    <v-row>
                        <!-- <body-editor
                            v-if="hasFeedForward"
                            :is-only-view="isOnlyView"
                            :body-value="editedItem.feedforward.value"
                            :body-value-initial="item && item.feedforward ? item.feedforward : ''"
                            :title="$t('admin.cards.feedForward')"
                            @update:bodyValue="editedItem.feedforward.value = $event"
                        />

                        <body-editor
                            v-if="hasFeedBack"
                            :is-only-view="isOnlyView"
                            :body-value="editedItem.feedback.value"
                            :body-value-initial="item && item.feedback ? item.feedback : ''"
                            :title="$t('admin.cards.feedback')"
                            @update:bodyValue="editedItem.feedback.value = $event"
                        /> -->

                        <div class="d-flex flex-wrap justify-space-around w-full">
                            <v-text-field
                                style="max-width: 80px"
                                v-model="editedItem.order.value"
                                class="purple-input"
                                :label="$t('admin.cards.order')"
                                :error-messages="editedItem.order.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.order.error = ''"
                            />

                            <v-switch
                                v-model="editedItem.active.value"
                                inset
                                :label="$t('admin.cards.activate')"
                                :disabled="isOnlyView"
                            />
                        </div>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer />

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${isOnlyView ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios';
import rules from '@/helpers/rulesValidate';
import { mapGetters, mapActions } from 'vuex';
import bodyEditor from '@/components/admin/posts/form/bodyEditor';
import cardLayoutHelper from '@/helpers/cardLayout';
import CardsAPIService from '@/services/CardsAPIService';

const DEFAULT_LANG = process.env.VUE_APP_DEFAULT_LANGUAGE;

export default {
    components: { bodyEditor },
    data: function () {
        return {
            urlMain: 'admin/games/cards',
            gameId: null,
            rules: {
                ...rules
            },
            item: null,
            editedItem: this.getEditedItem(),
            cardTypes: [],
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            cardLayouts: [],
            cardsI18nMapByLang: {},
            i18nMapComponents: {}
        };
    },
    computed: {
        ...mapGetters('languages', ['getLang', 'getLanguages']),

        hasAnswer() {
            const card_layout_id = this.editedItem.card_layout_id.value;

            return cardLayoutHelper.hasAnswer({ card_layout_id });
        },
        hasFeedForward() {
            const card_layout_id = this.editedItem.card_layout_id.value;

            return cardLayoutHelper.hasFeedForward({ card_layout_id });
        },
        hasFeedBack() {
            const card_layout_id = this.editedItem.card_layout_id.value;

            return cardLayoutHelper.hasAnswer({ card_layout_id });
        },
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('admin.cards.view') : this.$t('admin.cards.edit');
            } else return this.$t('admin.cards.new');
        }
        // ...mapGetters(['role', 'cities'])
    },
    async created() {
        await this.fetchCardTypes();

        await this.fetchCardLayouts();

        await this.fetchLanguages();

        // console.log(this.item);
        /* console.log('this.$route.params')
        console.log(this.$route.params) */
    },
    /* watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    }, */
    async mounted() {
        this.item = this.$route.params.item ?? null;

        await this.fetchCard();

        await this.createI18nMapComponents();
    },
    methods: {
        ...mapActions('languages', ['fetchLanguages']),

        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=games/cards/' + image : '';
        },
        getEditedItem(itemInit = null) {
            const item = itemInit ?? this.$route.params.item ?? null;

            var empty = !item;

            return {
                id: empty ? '' : item.id,
                card_type_id: {
                    value: empty ? '' : +item.card_type_id,
                    error: ''
                },
                card_layout_id: {
                    value: item?.card_layout_id ? +item.card_layout_id : '',
                    error: ''
                },
                question: {
                    value: empty ? '' : item.question,
                    error: ''
                },
                question_self: {
                    value: empty ? '' : item.question_self,
                    error: ''
                },
                answer_ok: {
                    value: empty ? '' : item.answer_ok,
                    error: ''
                },
                answer_1: {
                    value: empty ? '' : item.answer_1,
                    error: ''
                },
                answer_2: {
                    value: empty ? '' : item.answer_2,
                    error: ''
                },
                answer_3: {
                    value: empty ? '' : item.answer_3,
                    error: ''
                },
                answer_4: {
                    value: empty ? '' : item.answer_4,
                    error: ''
                },
                answer_5: {
                    value: empty ? '' : item.answer_5,
                    error: ''
                },
                active: {
                    value: empty ? true : !!JSON.parse(String(item.active).toLowerCase()),
                    error: ''
                },
                order: {
                    value: empty ? '' : +item.order,
                    error: ''
                },
                img: {
                    value:
                        empty || !item.image
                            ? []
                            : new File([item.image], item.image, {
                                  type: 'text/plain'
                              }),
                    error: '',
                    url: empty || !item.image ? '' : this.getImagePath(item.image)
                },
                feedback: {
                    value: item?.feedback ?? '',
                    error: ''
                },
                feedforward: {
                    value: item?.feedforward ?? '',
                    error: ''
                }
            };
        },
        preview_img() {
            if (this.editedItem.img.value && !(this.editedItem.img.value instanceof Array)) {
                this.editedItem.img.url = URL.createObjectURL(this.editedItem.img.value);
            } else {
                this.editedItem.img.url = null;
                this.editedItem.img.value = [];
            }
        },

        initialize() {},

        async save() {
            const formData = new FormData();
            formData.append('card_type_id', this.editedItem.card_type_id.value);
            formData.append('card_layout_id', this.editedItem.card_layout_id.value ?? '');
            formData.append('question', this.editedItem.question.value ?? '');
            formData.append('question_self', this.editedItem.question_self.value ?? '');

            // formData.append('feedback', this.editedItem.feedback.value ?? '');
            formData.append('feedforward', this.editedItem.feedforward.value ?? '');

            /* formData.append('answer_ok', this.editedItem.answer_ok.value ?? '');

            formData.append('answer_1', this.editedItem.answer_1.value ?? '');
            formData.append('answer_2', this.editedItem.answer_2.value ?? '');
            formData.append('answer_3', this.editedItem.answer_3.value ?? '');
            formData.append('answer_4', this.editedItem.answer_4.value ?? '');
            formData.append('answer_5', this.editedItem.answer_5.value ?? ''); */

            formData.append('active', this.editedItem.active.value ? 1 : 0);
            formData.append('image', this.editedItem.img.value);
            if (this.editedItem.order.value) formData.append('order', this.editedItem.order.value);

            const i18n = Object.entries(this.i18nMapComponents).map(itemI18n => {
                const language = itemI18n[0];
                const { question, question_self, feedforward } = itemI18n[1];

                return {
                    language,
                    question,
                    question_self,
                    feedforward: feedforward || ''
                };
            });
            formData.append('i18n', JSON.stringify(i18n));

            let urlAppend = '';
            let method = 'POST';
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id;
                method = 'PATCH';

                formData.append('_method', method);
            }

            try {
                const result = await axios.post(this.urlMain + urlAppend, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.afterSave(result.data.card);

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK';
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG));
                }
            } catch (error) {
                /* console.log('********** errors ********')
                console.log(error) */

                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach(e => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail;
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail;
                            }
                        });
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'));
            }
        },
        afterSave(item) {
            this.isOnlyView = true;

            this.editedItem = this.getEditedItem(item);
        },
        async fetchCardTypes() {
            try {
                const urlTypes = `${this.urlMain}/types`;

                const types = await axios.get(urlTypes);

                this.cardTypes = types.data.cardTypes;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },
        async fetchCardLayouts() {
            try {
                const result = await CardsAPIService.getCardsLayout({});

                this.cardLayouts = result.data.cardLayouts;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        async fetchCard() {
            try {
                const cardId = this.item?.id;
                if (cardId || cardId === 0) {
                    const result = await CardsAPIService.getCardForAdmin({ cardId });

                    // this.item = result.data.card;
                    // this.editedItem = this.getEditedItem(item);
                    const cardsI18n = result.data.cardsI18n;

                    this.cardsI18nMapByLang = cardsI18n.reduce((result, cardI18n) => {
                        result[cardI18n.language_id] = cardI18n;

                        return result;
                    }, {});
                }
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        async createI18nMapComponents() {
            this.getLanguages?.forEach(lang => {
                if (DEFAULT_LANG !== lang.code) {
                    const cardI18n = this.cardsI18nMapByLang?.[lang.code] ?? {};
                    const langData = {
                        nameLang: lang.name,
                        ...cardI18n
                    };
                    this.$set(this.i18nMapComponents, lang.code, langData);
                }
            });
        }
    }
};
</script>

<style></style>
