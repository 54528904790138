import BaseApiServices from '@/services/BaseApiServices';
import axios from '@/plugins/axios';

class CardsAPIService extends BaseApiServices {
    #URL_MAIN_ADMIN = 'admin/games/cards';

    async getCardsForAdmin({ signal = null }) {
        //controller = new AbortController();

        const url = `${this.#URL_MAIN_ADMIN}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getCardForAdmin({ cardId, signal = null }) {
        const url = `${this.#URL_MAIN_ADMIN}/${cardId}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getCardsLayout({ signal = null }) {
        //controller = new AbortController();

        const url = 'admin/games/cards/layouts';

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }
}

export default new CardsAPIService();
